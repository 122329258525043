import { useMemo } from "react";
import PropTypes from "prop-types";

import * as styles from "./button.module.scss";

export const Button = ({

  children,
  className,
  wrapper: Wrapper,
  ...props
}) => {

  const wrapperClassName = useMemo(
    () => [styles.wrapper, className].filter(Boolean).join(" "),
    [className]
  );

  return (
    <Wrapper className={wrapperClassName} {...props}>
      {children}
    </Wrapper>
  );
};

Button.defaultProps = {
  className: null,
  wrapper: "button",
  onClick: () => {},
};

Button.propTypes = {
  className: PropTypes.string,
  wrapper: PropTypes.elementType,
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
};
