export const isObject = (value) => typeof value === "object" && value !== null;

export const getProperty = (object, path) => {
    if (!isObject(object)) {
        return null;
    }

    if (typeof path !== "string") {
        return null;
    }

    return path.split(".").reduce((result, key) => (isObject(result) ? result[key] : result), object);
};

export const setProperty = (object, path, value) => {
    if (!isObject(object)) {
        return;
    }

    if (typeof path !== "string") {
        return;
    }

    if (typeof value === "undefined") {
        return;
    }

    path = path.split(".");

    return path.reduce((result, key, index) => {
        if (index === path.length - 1) {
            result[key] = value;
            return value;
        }

        if (!isObject(result[key])) {
            result[key] = {};
        }

        return result[key];
    }, object);
};

export const removeProperty = (object, property) => {
    if (!isObject(object)) {
        return;
    }

    if (typeof property !== "string") {
        return;
    }

    const { [property]: _, ...rest } = object;

    return rest;
};
