import { useMemo } from "react";
import PropTypes from "prop-types";

import { Markdown } from "../../utils";

import { Input } from "./input";

import * as styles from "./checkbox.module.scss";

let CHECKBOX_AUTO_ID = 0;

export const CheckboxField = ({ id, value, label, className, onChange, ...props }) => {
  const inputClassName = useMemo(
    () => [className ? className : "", styles.input].join(" "),
    [className]
  );

  const checkboxId = useMemo(() => id || `checkbox-${CHECKBOX_AUTO_ID++}`, [id]);

  return (
    <label htmlFor={checkboxId} className={styles.wrapper}>
      <Input
        type="checkbox"
        id={checkboxId}
        checked={value}
        value={`${value}`}
        className={inputClassName}
        onChange={(event) => onChange(event.target.checked)}
        {...props}
      />
      <div className={styles.checkmark} />
      <Markdown inline={true} content={label} />
    </label>
  );
};

CheckboxField.propTypes = {
  ...Input.propTypes,
  value: PropTypes.bool.isRequired,
};
