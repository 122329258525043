import { graphql } from 'gatsby';

import { Page } from '../components/layout';

import { Form } from "./lottery/form";

// import * as styles from './lottery.module.scss';

const Lottery = ({
	data: {
		markdownRemark: {
			frontmatter: { form },
			fields: { slug },
			html,
		},
	},
}) => (
	<Page slug={slug} body={html}>
		<Form form={form} />
	</Page>
);

export const lotteryQuery = graphql`
	query lotteryQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			frontmatter {
				form {
					state {
						idle {
							button
						}
						sending {
							button
						}
						success {
							title
							content
							button
							
						}
						failure {
							title
							content
							errors {
								required
							}
						}
						retrying {
							button
						}
					}
					
					fields {
						name
						type
						label
						required
					}
				}
			}
			fields {
				slug
			}
			html
		}
	}
`;

export default Lottery;
