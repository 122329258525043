import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import * as styles from "./select.module.scss";

import { Input } from "./input";

export const SelectField = ({
  options,
  onChange,
  error = null,
  className = "",
  getOptionLabel = (option) => option,
  getOptionValue = (option) => option,
  ...props
}) => {
  const selectClassName = useMemo(
    () => [className ? className : "", styles.wrapper].join(" "),
    [className]
  );

  return (
    <Fragment>
      <div className={styles.arrow} />
      <select
        {...props}
        className={selectClassName}
        onChange={(event) => onChange(event.target.value)}
      >
        <option key="default" value=""></option>
        {options.map((option, index) => (
          <option key={index} value={getOptionValue(option)}>
            {getOptionLabel(option)}
          </option>
        ))}
      </select>
    </Fragment>
  );
};

SelectField.propTypes = {
  ...Input.propTypes,
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
};
