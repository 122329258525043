import { useMemo } from "react";

import { Input } from "./input";

import * as styles from "./text-area.module.scss";

export const TextAreaField = ({ className, onChange, ...props }) => {
  const textAreaClassName = useMemo(
    () => [className ? className : "", styles.wrapper].join(" "),
    [props.className]
  );

  return (
    <textarea
      {...props}
      className={textAreaClassName}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

TextAreaField.propTypes = {
  ...Input.propTypes,
};
