import { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";

import * as styles from "./input.module.scss";

export const Input = forwardRef(function Input({ error = null, className = null, ...props }, ref) {
  const inputClassName = useMemo(
    () => [className ? className : "", styles.wrapper].join(" "),
    [props.className]
  );

  return <input {...props} ref={ref} className={inputClassName} />;
});

Input.propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
