// extracted by mini-css-extract-plugin
export var back = "form-module--back--15aFd";
export var failure = "form-module--failure--ApB5z";
export var field = "form-module--field--c+0f9";
export var form = "form-module--form--LwSnv";
export var message = "form-module--message--hocw0";
export var remark = "form-module--remark--intdt";
export var retry = "form-module--retry--hWGLi";
export var submit = "form-module--submit--oWY73";
export var success = "form-module--success--7a455";
export var title = "form-module--title---FDlq";