import { Input } from "./input";

export const NumberField = ({ onChange, ...props }) => (
  <Input
    min="0"
    step="1"
    type="number"
    inputMode="decimal"
    onChange={(event) => onChange(event.target.value)}
    {...props}
  />
);

NumberField.propTypes = {
  ...Input.propTypes,
};
