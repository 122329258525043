import { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { removeProperty } from "../../utils/object";

import { CheckboxField } from "./fields/checkbox";
import { DateField } from "./fields/date";
import { EmailField } from "./fields/email";
import { NumberField } from "./fields/number";
import { SelectField } from "./fields/select";
import { TextField } from "./fields/text";
import { TextAreaField } from "./fields/text-area";

import * as styles from "./field.module.scss";

const getFieldProps = ({ error, submitted, label, required }) => ({
  error,
  label,
  required,
  submitted,
});
const getInputProps = ({ error, submitted, ...inputProps }) => inputProps;
const makePropTypes = (propTypes) => ({
  ...removeProperty(Field.propTypes, "input"),
  ...propTypes,
});

let FIELD_AUTO_ID = 0;

export const Field = ({ label, input, error = null, required = false, submitted = false }) => {
  const [touched, setTouched] = useState(false);

  const id = useMemo(() => `field-${FIELD_AUTO_ID++}`, []);
  const wrapperClassName = useMemo(
    () =>
      [
        styles.wrapper,
        error ? styles.error : "",
        touched ? styles.touched : "",
        required ? styles.required : "",
        submitted ? styles.submitted : "",
      ].join(" "),
    [error, required, touched, submitted]
  );

  return (
    <div className={wrapperClassName}>
      <input.type
        {...input.props}
        id={id}
        className={styles.input}
        data-value={input.props.value || ""}
        onBlur={() => setTouched(true)}
      />
      {label && (
        <label htmlFor={id} className={styles.label}>
            <span className={styles.text}>{label}</span>
        </label>
      )}
      <span className={styles.message}>
        {error}
      </span>
    </div>
  );
};

Field.propTypes = {
  input: PropTypes.element.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  submitted: PropTypes.bool,
};

Field.Checkbox = (props) => (
  <Field {...getFieldProps(props)} label="" input={<CheckboxField {...getInputProps(props)} />} />
);
Field.Checkbox.propTypes = makePropTypes(CheckboxField.PropTypes);

Field.Date = (props) => (
  <Field {...getFieldProps(props)} input={<DateField {...getInputProps(props)} />} />
);
Field.Date.propTypes = makePropTypes(DateField.PropTypes);

Field.Email = (props) => (
  <Field {...getFieldProps(props)} input={<EmailField {...getInputProps(props)} />} />
);
Field.Email.propTypes = makePropTypes(EmailField.PropTypes);

Field.Number = (props) => (
  <Field {...getFieldProps(props)} input={<NumberField {...getInputProps(props)} />} />
);
Field.Number.propTypes = makePropTypes(NumberField.PropTypes);

Field.Select = (props) => (
  <Field {...getFieldProps(props)} input={<SelectField {...getInputProps(props)} />} />
);
Field.Select.propTypes = makePropTypes(SelectField.PropTypes);

Field.Text = (props) => (
  <Field {...getFieldProps(props)} input={<TextField {...getInputProps(props)} />} />
);
Field.Text.propTypes = makePropTypes(TextField.PropTypes);

Field.TextArea = (props) => (
  <Field {...getFieldProps(props)} input={<TextAreaField {...getInputProps(props)} />} />
);
Field.TextArea.propTypes = makePropTypes(TextAreaField.PropTypes);
