import { useMemo } from "react";

import { Input } from "./input";

import * as styles from "./date.module.scss";

export const DateField = ({ onChange, className, ...props }) => {
  const inputClassName = useMemo(
    () => [className ? className : "", styles.input].join(" "),
    [className]
  );

  return (
    <Input
      type="date"
      className={inputClassName}
      onChange={(event) => onChange(event.target.value)}
      {...props}
    />
  );
};

DateField.propTypes = {
  ...Input.propTypes,
};
