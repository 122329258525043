import { Fragment, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from 'gatsby';

import { getProperty } from "../../utils/object";

import { Field } from "../../components/form/field";
import { Button } from "../../components/form/button";
import { Markdown } from "../../components/utils";

import { usePageByKey } from "../../hooks/use-pages";


import * as styles from "./form.module.scss";

const FORM_ATTRS = {
    action: "/",
    name: "lottery",
    method: "POST",
    acceptCharset: "UTF-8",
    encType: "multipart/form-data",
    "data-netlify": true,
    "data-netlify-honeypot": "bot-field"
};

const FIELDS_MAPPING = {
    CHECKBOX: Field.Checkbox,
    DATE: Field.Date,
    EMAIL: Field.Email,
    NUMBER: Field.Number,
    SELECT: Field.Select,
    TEXT: Field.Text,
    TEXTAREA: Field.TextArea,
};

const TRANSMISSION_STATUS = {
    IDLE: 1,
    SENDING: 2,
    SUCCESS: 3,
    FAILURE: 4,
    RETRYING: 5,
};

const fieldFactory = ({ type, ...fieldProps }, formProps) => {
    const Component = FIELDS_MAPPING[type];
    if (!Component) {
        return null;
    }

    return <Component {...fieldProps} {...formProps} />;
};

const valueProcessor = ({ type }, value) => {
    if (value) {
        return value;
    }

    switch (type) {
        case "CHECKBOX":
            return false;
        default:
            return "";
    }
};

const valueValidator = ({ required }, value) => {
    if (!required) {
        return true;
    }

    return !!value;
};

export const Form = ({ form }) => {

    const page = usePageByKey("index");
    const path = getProperty(page, "slug");
    const remarkRef = useRef(null);
    const messageRef = useRef(null);

    const [formState, setFormState] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const [transmissionStatus, setTransmissionStatus] = useState(TRANSMISSION_STATUS.IDLE);

    const fields = getProperty(form, "fields") || [];

    const labelRetry = getProperty(form, "state.retrying.button");
    const labelSubmit = getProperty(form, "state.idle.button");
    const labelSending = getProperty(form, "state.sending.button");
    const labelSuccess = getProperty(form, "state.success.button");
    const errorMessage = getProperty(form, "state.failure.errors.required");

    const successTitle = getProperty(form, "state.success.title");
    const failureTitle = getProperty(form, "state.failure.title");
    const successContent = getProperty(form, "state.success.content");
    const failureContent = getProperty(form, "state.failure.content");

    const encodeData = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const transmitData = async () => {
        const response = await fetch(FORM_ATTRS.action, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encodeData({ "form-name": FORM_ATTRS.name, ...formState }),
        });

        if (!response.ok) {
            throw new Error(`Failed with response: ${await response.text()}`);
        }
    };

    const retryHandler = async () => {
        try {
            setTransmissionStatus(TRANSMISSION_STATUS.RETRYING);
            await transmitData();
            setTransmissionStatus(TRANSMISSION_STATUS.SUCCESS);
        } catch (e) {
            setTransmissionStatus(TRANSMISSION_STATUS.FAILURE);
            console.error(e);
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();

        const remark = getProperty(remarkRef, "current.value");
        if (remark) {
            return;
        }

        try {
            setTransmissionStatus(TRANSMISSION_STATUS.SENDING);
            await transmitData();
            setTransmissionStatus(TRANSMISSION_STATUS.SUCCESS);
        } catch (e) {
            setTransmissionStatus(TRANSMISSION_STATUS.FAILURE);
            console.error(e);
        }
    };

    useEffect(() => {
        switch (transmissionStatus) {
            case TRANSMISSION_STATUS.SUCCESS:
            case TRANSMISSION_STATUS.FAILURE: {
                const wrapper = getProperty(messageRef, "current");
                if (wrapper) {
                    // required for `showMenu` to work with `scrollDelta < 0`
                    window.scrollTo({ top: 1, behavior: "instant" });
                    setTimeout(() => {
                        wrapper.scrollIntoView({ behavior: "smooth" });
                    }, 100);

                }
                break;
            }
            default:
        }
    }, [messageRef, transmissionStatus]);

    return (
        <Fragment>

            {transmissionStatus === TRANSMISSION_STATUS.SUCCESS && (
                <div ref={messageRef} className={styles.message}>
                    <div className={styles.success}>
                        <h3 className={styles.title}>{successTitle}</h3>
                        <Markdown content={successContent} />
                    </div>
                    <div className={styles.back}>
                        <Button wrapper={Link} to={path}>{labelSuccess}</Button>
                    </div>
                </div>
            )}
            {(transmissionStatus === TRANSMISSION_STATUS.FAILURE ||
                transmissionStatus === TRANSMISSION_STATUS.RETRYING) && (
                <div ref={messageRef} className={styles.message}>
                    <div className={styles.failure}>
                        <h3 className={styles.title}>{failureTitle}</h3>
                        <Markdown content={failureContent} />
                    </div>
                    <div className={styles.retry}>
                        <Button
                            onClick={retryHandler}
                            disabled={transmissionStatus === TRANSMISSION_STATUS.RETRYING}
                        >
                            {transmissionStatus === TRANSMISSION_STATUS.RETRYING ? labelSending : labelRetry}
                        </Button>
                    </div>
                </div>
            )}
            {(transmissionStatus === TRANSMISSION_STATUS.IDLE ||
                transmissionStatus === TRANSMISSION_STATUS.SENDING) && (
                <form {...FORM_ATTRS} onSubmit={submitHandler} className={styles.form}>
                    {fields.map((field) =>  (
                            <div key={getProperty(field, "name")} className={styles.field}>
                                {fieldFactory(field, {
                                    submitted,
                                    error: valueValidator(field, formState[field.name]) ? null : errorMessage,
                                    value: valueProcessor(field, formState[field.name]),
                                    onChange: (value) => {
                                        setFormState((form) => ({
                                            ...form,
                                            [field.name]: value,
                                        }));
                                    },
                                })}
                            </div>
                        )
                    )}

                    <input ref={remarkRef} type="text" className={styles.remark} />
                    {/* https://docs.netlify.com/forms/setup/#work-with-javascript-rendered-forms */}
                    <input type="hidden" name="form-name" value={FORM_ATTRS.name} />
                    <div className={styles.submit}>
                        <Button
                            type="submit"
                            onClick={() => setSubmitted(true)}
                            disabled={transmissionStatus === TRANSMISSION_STATUS.SENDING}
                        >
                            {transmissionStatus === TRANSMISSION_STATUS.SENDING ? labelSending : labelSubmit}
                        </Button>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

Form.defaultProps = {
    form: {},
};

Form.propTypes = {
    form: PropTypes.shape({
        state: PropTypes.shape({
            idle: PropTypes.shape({
                button: PropTypes.string.isRequired,
            }).isRequired,
            sending: PropTypes.shape({
                button: PropTypes.string.isRequired,
            }).isRequired,
            retrying: PropTypes.shape({
                button: PropTypes.string.isRequired,
            }).isRequired,
            success: PropTypes.shape({
                title: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            }).isRequired,
            failure: PropTypes.shape({
                title: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
                errors: PropTypes.shape({
                    required: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                required: PropTypes.bool.isRequired,
                options: PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.string.isRequired,
                        label: PropTypes.string.isRequired,
                    }).isRequired,
                ),
            }).isRequired,
        ).isRequired,
    }).isRequired,
};
