import { Input } from "./input";

export const EmailField = ({ onChange, ...props }) => (
  <Input
    type="email"
    inputMode="email"
    onChange={(event) => onChange(event.target.value)}
    {...props}
  />
);

EmailField.propTypes = {
  ...Input.propTypes,
};
